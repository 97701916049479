import React from 'react';
import { FaCopyright, FaPhone } from 'react-icons/fa';

import { AiOutlineMail } from 'react-icons/ai';

export const Contact = (props) => {
  return (
    <div>
      <div id="contact" className="bg-white mt-5">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-[#002e5b] mt-4 font-bold text-xl">Contact Us</h1>
          <h2 className="text-[#002e5b] md:py-4 md:px-0 xs:px-10 justify-center text-center">
            Mzulia International Limited is ready to supply and solve
          </h2>
          <h2 className="text-[#002e5b] py-1">your most complex challenges</h2>
        </div>
        <div className="flex items-center justify-center w-full">
          <div className="pt-10 items-center md:flex flex-col justify-center w-full">
            {/* <div className="w-[200px] h-[100px]"><Maps/></div> */}

            <div className=" pt-2 flex">
              <div className="w-full">
                <a href="https://www.google.com/maps/place/Jeevan+Bharati+House,+Nairobi/@-1.288368,36.8261208,17z/data=!3m1!4b1!4m6!3m5!1s0x182f112794a2e2ed:0xa81d36a0722eb319!8m2!3d-1.288368!4d36.8261208!16s%2Fg%2F12hnwzrrs?entry=ttu">
                  <img
                    className="rounded-lg md:block hidden"
                    src="/maps.png"
                    alt="Location Map"
                  />
                  <img
                    className="rounded-lg h-80 object-cover md:hidden block"
                    src="/maps.png"
                    alt="Location Map"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
   
    </div>
      </div>
    </div>
  );
};


