import React from "react";
import Slider from "./Slider";
import { HomeSlider } from "./HomeSlider";
export const Header = (props) => {
  return (
    <header id="header">
      <div className="">
    
      <HomeSlider/>
      </div>
    </header>
  );
};
