import React, { useState, useEffect, useContext } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Aboutus } from "./components/aboutus";
// import { Galery } from "./components/galery";
// import { Projects } from "./components/Projects";
// import { Customerfeedback } from "./components/customerfeedback";
// import { Services } from "./components/services";
import { Team } from "./components/team";

import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Solution } from "./components/oursolution";


import "./App.css";
import { ProductsPage } from "./components/product";
import { FaCopyright, FaPhone } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const [details, setDetails] = useState(false);


// Custom hook to observe sections in view
const useInViewSection = (sections) => {
  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) observer.observe(element);
    });

    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) observer.unobserve(element);
      });
    };
  }, [sections]);

  return currentSection;
};

  const sections = [
    'header',
    'features',
    'Solution',
    'products',
    'team',
    'contact',
  ];
  const currentSection = useInViewSection(sections);

  useEffect(() => {
    if (currentSection) {
      window.history.replaceState(null, '', `#${currentSection}`);
    }
  }, [currentSection]);



  return (
    <div>
  
      
      <Navigation current={currentSection} />
     {!details &&  <div>
      <Header data={landingPageData.Header} /></div>}
      {!details &&   <div>
      <Aboutus data={landingPageData.Aboutus} />
      </div>}
   
      {!details && 
      <div>
      <Solution data={landingPageData.Solution} />
      </div>}
      <div>
      <ProductsPage details={details} setDetails={setDetails}/>
      </div>
        {!details && 
      <div>
      <Team data={landingPageData.Team} />
      </div>}
      {/* <Setup data={landingPageData.Setup} /> */}
      {!details && 
      <div>
      <Contact data={landingPageData.Contact} />
      </div>}
     
      <div>
      <div className="bg-white ">
        <div className="p-3 md:flex items-center justify-between gap-10 px-[20px] lg:px-[200px] bg-[#219292] ">
          <div className="flex text-white items-center text-[17px] justify-center">
            <FaPhone /> &nbsp; <h2>+254 721 892 384</h2>
          </div>
          <div className="flex text-white items-center text-[17px] justify-center">
            <AiOutlineMail />
            &nbsp; <h2>@info@mzulia.com</h2>
          </div>
          <div>
            <h2 className="py-2 flex items-center text-white justify-center">
              <FaCopyright /> &nbsp; Mzulia Int. Ltd.
            </h2>
          </div>
        </div>
      </div>
      </div>
   
    </div>
  );
};

export default App;
