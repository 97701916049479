import React, { ReactNode, useState } from 'react';
import { Bounce, Fade } from 'react-awesome-reveal';


const ReadMore = ({ children }) => {
  const text = children; // Cast to string for text slicing
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text">
      {isReadMore ? text.slice(0, 300) : text}
      <button>
        <span
          onClick={toggleReadMore}
          className="read-or-hide text-[#1639d2] ml-1"
        >
          {isReadMore ? '...read more' : ' show less'}
        </span>
      </button>
    </p>
  );
};

export const Solution = () => {
  return (
    <div id='Solution' className="w-full bg-white">
      <div className="bg-[#219292] px-3 md:flex justify-between md:px-[100px] items-center">
        <div className="text-white">
          <Fade cascade damping={0.1}>
            <p className="text-[11px] md:text-[17px] pt-2 md:mt-0">
              We are the ideal business partner you&apos;ve been searching for:
            </p>
          </Fade>
        </div>
        <div className="py-2  md:block hidden">
          <a href="/">
            <button className="rounded-sm p-2 bg-blue-900 text-[10px] text-white md:text-[15px]">
              CONTACT NOW
            </button>
          </a>
        </div>
        <div className="py-2 md:hidden bock flex justify-center">
          <a href="https://matrix-resources-limited-kenya.business.site/">
            <Bounce>
              <button className="rounded-sm p-2 bg-blue-900 text-[10px] text-white md:text-[15px]">
                CONTACT NOW
              </button>
            </Bounce>
          </a>
        </div>
      </div>
      <div className="overflow-hidden">
        <Fade cascade damping={0.3} direction="up">
          <div className="text-center bg-white pt-8">
            <h1 className="text-[#002e5b] py-4 font-bold text-xl">
              Our Services
            </h1>
            <h2 className="text-[#002e5b] py-4 text-wrap">
              We provide quality goods and service to our clients in a timely
              manner
            </h2>
          </div>
        </Fade>
        <div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 pt-5 pb-12 gap-x-2 gap-y-5 px-[100px] justify-center">
            <Fade cascade damping={0.3}>
              <div className="flex flex-col items-center ">
                <div className="py-2 shadow-2xl rounded-lg ">
                  <div className="object-cover h-[200px] w-[300px]">
                    <img
                      className="object-cover h-[200px] w-[300px] rounded-t-lg"
                      src="/fiscal.jpg"
                      alt="Construction Works"
                    />
                  </div>
                  <div className="w-[300px] relative bg-gradient-to-br from-[#bee7e7] via-white to-white p-5">
                    <Fade cascade damping={0.3} direction="up">
                      <Bounce>
                        <h1 className="text-[#002e5b] py-4 text-[17px] font-bold">
                          Fiscalisation
                        </h1>
                      </Bounce>
                      <p className="text-[13px] text-start !text-black">
                        <ReadMore>
                          Mzulia Internatinal is a KRA approved Value Added
                          Reseller (VAR) for Tax Invoice Management System
                          (TIMS) devices since 2021. We supply approved devices
                          for Pergamon Ltd who are the approved suppliers for
                          Incotex, Bulgaria. With over 1,000 installations under
                          our belt, we are allowing leading companies and their
                          distributors, small and large retail outlets and
                          service providers to not just fully comply with this
                          requirement but to enhance their business. We have in
                          this role implemented fiscal devices throughout the
                          entire Petroleum Outlets Association of Kenya (POAK)
                          dealer network having been selected through
                          competitive bidding process. Following the
                          implementation of eTIMS which is the successor to
                          TIMS, we have partnered with TRING, Bosnia and
                          Herzegovina to supply touch screen point of sale
                          devices that are eTIMS compliant as local
                          distributors.
                        </ReadMore>
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <div className="py-2 shadow-2xl rounded-lg ">
                  <div className="object-cover h-[200px] w-[300px]">
                    <img
                      className="object-cover h-[200px] w-[300px] rounded-t-lg"
                      src="/Networking.jpg"
                      alt="Construction Works"
                    />
                  </div>
                  <div className="w-[300px] relative bg-gradient-to-br from-[#bee7e7] via-white to-white p-5">
                    <Fade cascade damping={0.3} direction="up">
                      <Bounce>
                        <h1 className="text-[#002e5b] py-4 text-[17px] font-bold">
                          Networking
                        </h1>
                      </Bounce>
                      <p className="text-[13px] text-start !text-black">
                        <ReadMore>
                          We are local partners for Cisco, Huawei, Siemon,
                          Nexans and Giganet enterprise network solutions.
                          Mzulia International ltd has partnered with these
                          leading companies to leverage on the wide experience p
                          esented by this strategic venture. The partnership has
                          already yielded results through certifications of our
                          technical team giving our clientele the much needed
                          reassurance that they are in safe hands with our team.
                          The range of services in this field include
                          cabling(data and voice), rack & cabinet installations,
                          Data center & server room wiring/cable management,
                          Conduit & wire tray implementations, cabling standards
                          cable certification, structured cabling & labeling and
                          termination. To date we have successfully designed and
                          completed a variety of network solutions for cyber
                          cafes, corporate offices, schools, saccos, government
                          institutions and hospitals.
                        </ReadMore>
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center ">
                <div className="py-2 shadow-2xl rounded-lg ">
                  <div className="object-cover h-[200px] w-[300px]">
                    <img
                      className="object-cover h-[200px] w-[300px] rounded-t-lg"
                      src="/auto.jpg"
                      alt="Construction Works"
                    />
                  </div>
                  <div className="w-[300px] relative bg-gradient-to-br from-[#bee7e7] via-white to-white p-5">
                    <Fade cascade damping={0.3} direction="up">
                      <Bounce>
                        <h1 className="text-[#002e5b] py-4 text-[17px] font-bold">
                          Auto Car Parking Barriers
                        </h1>
                      </Bounce>
                      <p className="text-[13px] text-start !text-black">
                        <ReadMore>
                          We handle the importation of desktop computers and
                          laptops from leading global manufacturers. Our
                          meticulous import process includes quality control,
                          logistics management, and compliance with
                          international standards to ensure that each unit meets
                          your expectations. Whether you need powerful
                          workstations or efficient portable devices, we ensure
                          timely and secure delivery of high-quality equipment.
                          Our experienced technicians ensure that every device
                          is installed correctly and efficiently, providing
                          support and training as needed to get your team up and
                          running with minimal disruption. Whether you’re
                          setting up a new office or upgrading existing
                          equipment, we deliver customized solutions that
                          enhance productivity and performance.
                        </ReadMore>
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <div className="py-2 shadow-2xl rounded-lg ">
                  <div className="object-cover h-[200px] w-[300px]">
                    <img
                      className="object-cover h-[200px] w-[300px] rounded-t-lg"
                      src="/rfid.jpg"
                      alt="Construction Works"
                    />
                  </div>
                  <div className="w-[300px] relative bg-gradient-to-br from-[#bee7e7] via-white to-white p-5">
                    <Fade cascade damping={0.3} direction="up">
                      <Bounce>
                        <h1 className="text-[#002e5b] py-4 text-[17px] font-bold">
                          CCTV, RFID & Tracking
                        </h1>
                      </Bounce>

                      <p className="text-[13px] text-start !text-black">
                        <ReadMore>
                          We aim to provide our clients peace of mind in regard
                          to their businesses, homes and vehicles (personal and
                          commercial) quality cctv cameras and accessories. with
                          high end We offer tracking services for vehicles with
                          added capability to monitor fuel level and cargo area
                          temperature while on transit. The Warehouse RFID
                          technology we deploy monitors inventory within a
                          warehouse in realtime including stock levels, batch
                          movements, stock location making stock taking easy as
                          well as quality assurance. We are approved local
                          suppliers for Honeywell (RFID), HIKVision (CCTV),
                          Dahua (CCTV) and Galeb, Serbia (GPS Car Tracking)
                        </ReadMore>
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center ">
                <div className="py-2 shadow-2xl rounded-lg ">
                  <div className="object-cover h-[200px] w-[300px]">
                    <img
                      className="object-cover h-[200px] w-[300px] rounded-t-lg"
                      src="/disaster.jpg"
                      alt="Construction Works"
                    />
                  </div>
                  <div className="w-[300px] relative bg-gradient-to-br from-[#bee7e7] via-white to-white p-5">
                    <Fade cascade damping={0.3} direction="up">
                      <Bounce>
                        <h1 className="text-[#002e5b] py-4 text-[17px] font-bold">
                          Disaster Recovery Planning
                        </h1>
                      </Bounce>
                      <p className="text-[13px] text-start !text-black">
                        <ReadMore>
                          Effective business continuity planning requires the
                          timely restoration of critical functions after a disru
                          tion to normal business operations. This would include
                          developing plans for recovery of essential IT
                          infrastructure, critical applications, and time
                          sensitive business processes. In the aftermath of
                          emergency situations, however, it is all too common to
                          find that organizations fail to plan adequately to
                          protect and replicate paper records, as necessary to
                          mitigate risk and continue operations. Not only is
                          paper easily lost, but it is also very fragile and
                          easily destroyed. In case of a flood or a fire, many
                          vital records would be lost. Some records cannot be
                          recreated and by law they have to be retained for a
                          certain period of time. If records are lost, an
                          organization would not be able to present evidence in
                          court or and may have to settle cases due to lack of
                          evidence. We are currently in the process of finding
                          suitable international players to partner with in
                          these deployments.
                        </ReadMore>
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

