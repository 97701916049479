import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaBars } from "react-icons/fa";

import { Zoom } from "react-awesome-reveal";

export const Navigation = ({current}) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const [navbarOpen, setNavbarOpen] = useState(false);
   console.log('my current', current)
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    // Handle scroll for background change
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Intersection Observer setup
    const sections = document.querySelectorAll('section');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 } // Adjust this value as needed
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);
  return (
    <>
      <nav
      className={`flex items-center justify-between  px-5 w-full sticky top-0 z-10 ${scrolled
        ? 'bg-gradient-to-br shadow-lg  from-[#bee7e7] via-white to-white'
        : 'bg-gradient-to-r from-[#bee7e7] via-[#d3f0f0]  to-white'
        }`}
    >
        <div className="md:container md:mx-auto flex flex-wrap items-center md:justify-between md:relative w-full">
          <div className="w-full flex justify-between items-center lg:w-auto lg:static lg:block lg:justify-start">
          <div className="items-center justify-between flex py-2 lg:w-fit w-full">
        <div className="">
          <Zoom cascade damping={0.3}>
            <img src="/logo-no-background.png" alt="" className="h-[50px]" />
          </Zoom>
        </div>
        <div className="lg:hidden block">
          {/* <Sidebar current={current} /> */}
        </div>
      </div>
            <div>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                {/* <i className="fa fa-bars"></i> */}
                <FaBars className="text-[#219292]" />
              </button>
            </div>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center   lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded " : " hidden")
            }
            id="navbar-warning"
          >
            <ul className="md:flex navbar-nav list-none lg:ml-auto items-center justify-center  ">
              <li onClick={()=>setNavbarOpen(false)} className=" flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#header"
                  className={ `${current=== 'header'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                  onClick={scrollToTop}
                >
                  Home
                </a>
              </li>
              <li onClick={()=>setNavbarOpen(false)}  className="nav-item flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#features"
                  className={ `${current=== 'features'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                >
                  About Us
                </a>
              </li>
              <li onClick={()=>setNavbarOpen(false)}  className="nav-item flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#Solution"
                  className={ `${current=== 'Solution'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                >
                  Services
                </a>
              </li>
              <li onClick={()=>setNavbarOpen(false)}  className="nav-item flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#products"
                  className={ `${current=== 'products'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                >
                 Products
                </a>
              </li>
              <li onClick={()=>setNavbarOpen(false)}  className="nav-item flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#team"
                  className={ `${current=== 'team'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                >
                  Testimonials
                </a>
              </li>
              <li onClick={()=>setNavbarOpen(false)}  className="nav-item flex flex-col items-center text-center justify-ceneter">
                <a
                  href="#contact"
                  className={ `${current=== 'contact'? "!underline text-yellow-600":""} btn px-3 py-1 md:py-2 flex text-center items-center  uppercase font-[500] leading-snug text-[#219292]`}
                >
                  Contact
                </a>
              </li>

        
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
