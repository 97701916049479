import { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { BiArrowBack } from 'react-icons/bi';
import {
  FaBluetooth,
  FaFirefoxBrowser,
  FaMicrochip,
  FaPhone,
  FaSimCard,
} from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import { Autoplay, EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


// Initialize Swiper modules



export const ProductsPage = ({setDetails, details}) => {
  const [selectedProduct, setSelectedProduct] = useState({
    id: 0,
    name: '',
    images: [],
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    description: '',
    price: '',
    quantity_available: 0,
    specifications: [],
  });
 
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const data = [
    {
      id: 1,
      name: 'eTims Android POS',
      images: [
        '/products/Android1.jpg',
        '/products/Android2.jpg',
        '/products/Android3.jpg',
      ],
      desc1: 'Android 12',
      desc2: 'RAM 3G+ROM 16GB',
      desc3: '4G All Network WI-FI',
      desc4: 'SIM Card *2',
      description: 'A high-performance smartphone with an excellent camera.',
      price: '$699.99',
      quantity_available: 20,
      specifications: [
        { name: 'Operating System', value: 'Android 12.0' },
        { name: 'CPU Clock Range', value: '8-Core' },
        { name: 'RAM', value: '3GB (4G Optional)' },
        { name: 'FLASH', value: '16GB (32G/64G Optional)' },
        { name: 'LcD Screen', value: '5.5 inch' },
        { name: 'Display Resolution', value: '720*1280 IPS' },
        { name: 'Back Light', value: 'LED' },
        { name: 'Touch Screen', value: 'Capacitive Five-Point Touch' },
        {
          name: 'Fingerprint Collection Module(optional)',
          value: 'FBI/STQC Optional',
        },
        {
          name: 'SIM Card Bands',
          value:
            'Dual SIM 4G: FDD-LTE, TD-LTE 3G: WCDMA; 2G: GSM, GPRS Hot spot/Bluetooth',
        },
        { name: 'Voice Call', value: 'Support' },
        { name: 'Data', value: 'Support' },
        { name: 'WIFI Module', value: 'Support' },
        { name: 'Bluetooth', value: 'Support' },
        { name: 'GPS', value: 'Support' },
        { name: 'USB', value: 'Type-c' },
        { name: 'Rear Camera', value: '5 Mega-pixel' },
        { name: 'Scanner', value: 'Support, 2D Bar code scanner (CMOS)' },
        { name: 'Speaker', value: 'Support' },
        { name: 'Microphone', value: 'Support' },
        { name: 'Card Slot', value: 'SIM x2; SAM x1; Type-C x1' },
        {
          name: 'Physical Button',
          value: 'power button x1, Vol +/ x1, Scan x1',
        },
        { name: 'Dimension', value: '254.5mm * 82mm * 17mm' },
        {
          name: 'Weight',
          value: '400g (one package box including product is 500g)',
        },
        { name: 'Battery', value: 'Lithium Battery' },
        { name: 'Battery Capacity', value: '3.8V 6400mAh (7.6V 3200mAh)' },
        { name: 'Power Adapter', value: '5V/24' },
        { name: 'NFC', value: 'ISONEC 14443 Type AB, Mifare' },
        {
          name: 'Printer',
          value: 'Paper width: 58mm; Max. paper roll diameter: 40mm',
        },
        {
          name: 'Standard Accessories',
          value:
            '1pc power adaptor, 1pc User Manual, 1pc USB cable, 1 roll 58mm thermal paper',
        },
        {
          name: 'Temperature',
          value:
            'Storage temperature: -10°C-60°C, Working Temperature: 0°C-40°C',
        },
      ],
    },
  ];

  const data2 = [
    {
      id: 2,
      name: 'Sports Smartwatch',
      desc1: 'Bluetooth 5.2',
      desc2: 'One Chipset Double Model',
      desc3: 'Waterproof & Dustproof',
      desc4: 'Convenient call scenario',
      images: [
        '/products/Watch1.png',
        '/products/Watch2.png',
        '/products/Watch3.png',
      ],
      specifications: [
        { name: 'Dustproof', value: 'High quality dustproof design' },
        {
          name: 'Waterproof',
          value:
            '10M depth waterproof, extreme cold and high temperature resistance',
        },
        {
          name: 'AI',
          value: 'AI voice assistance, works inteligently and correctly',
        },
        { name: 'Battery', value: '410mAh pure cobalt cell ' },
      ],
    },
    {
      id: 3,
      name: 'C20Pro Smartwatch',
      desc1: 'Bluetooth 5.0',
      desc2: 'Android 6.0 IOS 9.0',
      desc3: 'Waterproof & Dustproof',
      desc4: 'Convenient call scenario',
      images: [
        '/products/Watchpro1.png',
        '/products/Watchpro2.png',
        '/products/Watchpro3.png',
      ],
      specifications: [
        { "name": "Memory:", "value": "128Mb" },
        { "name": "Touch Screen:", "value": "Capacitive touch TP Hynitron 816D" },
        { "name": "Screen:", "value": "1.83inch 240*286 resolution HD rectangle screen" },
        { "name": "Connect:", "value": "BT 5.0" },
        { "name": "Heart Rate:", "value": "HR3605" },
        { "name": "Pedometer:", "value": "QST QMA6100P, Accurate step counting" },
        { "name": "Charging:", "value": "2PIN magnetic charging cable" },
        { "name": "Battery:", "value": "Capacity 380mAH (polymer pure cobalt battery)" },
        { "name": "Waterproof:", "value": "IP68" },
        { "name": "Product Size:", "value": "Length54*width43*Thickness12.9mm" },
        { "name": "Color:", "value": "Black/Orange" },
        { "name": "N.W(watch):", "value": "52.7g (strap included)" },
        { "name": "Support:", "value": "Android 6.0 IOS 9.0 and above" },
        { "name": "Accessories:", "value": "Watch/Magnetic Charging Cable/Manual" }
      ]
      ,
    },

  ];

  const handleViewDetails = (product) => {
    setSelectedProduct(product);
    setDetails(true);
  };

  const handleBackToList = () => {
    setDetails(false);
  };

  return (
    <div id='products' className=' w-full  flex flex-col h-full '>
       

      <div className="bg-gradient-to-r from-[#bee7e7] via-[#d3f0f0]  to-white relative ">
      <Fade cascade damping={0.3} direction="up">
      {!details ?
          <div className="text-center  py-8">
            <h1 className="text-[#002e5b] py-4 font-bold text-xl">
              Our Products
            </h1>
           
          </div> : ""}
        </Fade>
        {!details ? (
          <div className="flex flex-wrap justify-center gap-6 p-6 mb-10">
          
            {data.map((product) => (
              <div
                key={product.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden w-72"
              >
                <div className="relative group">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    speed={1200}
                    autoplay={{
                      delay: 7000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay, EffectFade]}
                    zoom={true}
                    className="swiper-container"
                  >
                    {product.images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div className="swiper-zoom-container">
                          <img
                            src={image}
                            alt={product.name}
                            className="w-full h-40 object-contain"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className="p-4 bg-gradient-to-br from-[#bee7e7] via-white to-white">
                  <Fade cascade damping={0.3} direction="up">
                    <h3 className="text-lg font-semibold text-gray-800">
                      {product.name}
                    </h3>
                    <ul className="list-disc list-inside text-gray-600 mt-2">
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FiSettings />
                          </div>
                        </strong>{' '}
                        {product.desc1}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaMicrochip />
                          </div>
                        </strong>{' '}
                        {product.desc2}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaFirefoxBrowser />
                          </div>
                        </strong>{' '}
                        {product.desc3}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaSimCard />
                          </div>
                        </strong>{' '}
                        {product.desc4}
                      </li>
                    </ul>
                    <button
                      className="mt-4 px-4 py-2 bg-blue-500 w-full text-white rounded-lg hover:bg-blue-600"
                      onClick={() => handleViewDetails(product)}
                    >
                      View Details
                    </button>
                  </Fade>
                </div>
              </div>
            ))}

            {data2.map((product) => (
              <div
                key={product.id}
                className="bg-gradient-to-r from-[#bee7e7] via-[#d3f0f0]  to-white rounded-lg shadow-lg overflow-hidden w-72"
              >
                <div className="relative group">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    speed={1200}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay, EffectFade]}
                    zoom={true}
                    className="swiper-container"
                  >
                    {product.images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div className="swiper-zoom-container">
                          <img
                            src={image}
                            alt={product.name}
                            className="w-full h-40 object-contain"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <div className="p-4 bg-gradient-to-br from-[#bee7e7] via-white to-white">
                  <Fade cascade damping={0.3} direction="up">
                    <h3 className="text-lg font-semibold text-gray-800">
                      {product.name}
                    </h3>
                    <ul className="list-disc list-inside text-gray-600 mt-2">
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaBluetooth />
                          </div>
                        </strong>{' '}
                        {product.desc1}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaMicrochip />
                          </div>
                        </strong>{' '}
                        {product.desc2}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaFirefoxBrowser />
                          </div>
                        </strong>{' '}
                        {product.desc3}
                      </li>
                      <li className="flex gap-1 items-center mb-2">
                        <strong>
                          <div className="bg-blue-500 rounded-full p-2 text-white">
                            <FaPhone />
                          </div>
                        </strong>{' '}
                        {product.desc4}
                      </li>
                    </ul>
                    <button
                      className="mt-4 px-4 py-2 bg-blue-500 w-full text-white rounded-lg hover:bg-blue-600"
                      onClick={() => handleViewDetails(product)}
                    >
                      View Details
                    </button>
                  </Fade>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-6 bg-white ">
            <button
              className="mb-4 px-4 py-2 bg-[#bee7e7] text-blue-500 rounded-lg flex items-center gap-1"
              onClick={handleBackToList}
            >
              <BiArrowBack /> Back
            </button>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="relative">
                {/* Main Swiper */}
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  loop={true}
                  speed={1200}
                  autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Autoplay, EffectFade, Navigation]}
                  navigation
                  thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                  className="main-swiper"
                >
                  {selectedProduct?.images?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={image}
                        alt={selectedProduct?.name}
                        className="w-full h-80 object-contain"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                {/* Thumbnails Swiper */}
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  watchSlidesProgress={true}
                  className="thumbs-swiper !mt-3 !px-3"
                >
                  {selectedProduct?.images?.map((image, index) => (
                    <SwiperSlide key={index} className="!bg-slate-50 !p-2">
                      <img
                        src={image}
                        alt={`Thumbnail ${index}`}
                        className="w-full h-20 object-contain "
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="p-4 bg-gradient-to-br from-[#bee7e7] via-white to-white mt-3">
                <h3 className="text-2xl font-semibold text-gray-800">
                  {selectedProduct?.name}
                </h3>
                {/* <p className="text-gray-700 mb-4">{selectedProduct.description}</p>
                            <p className="text-lg font-bold mb-2">Price: {selectedProduct.price}</p>
                            <p className="text-sm text-gray-500">Quantity Available: {selectedProduct.quantity_available}</p> */}

                {/* Specifications Table */}
                <div className="mt-6">
                  <h4 className="text-xl font-semibold text-gray-800 mb-2">
                    Specifications
                  </h4>
                  <table className="w-full table-auto border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border-b px-4 py-2 text-left">
                          Feature
                        </th>
                        <th className="border-b px-4 py-2 text-left">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProduct?.specifications?.map(
                        (spec, index) => (
                          <tr key={index}>
                            <td className="border-b px-4 py-2">{spec.name}</td>
                            <td className="border-b px-4 py-2">{spec.value}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};


