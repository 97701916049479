// src/Testimonials.tsx

import React from 'react';
import { Fade } from 'react-awesome-reveal';



export const Team = () => {
  const testimonials = [
    {
      name: 'John Doe',
      text: 'This service was amazing! I highly recommend it to anyone looking for quality and reliability.',
      image: 'https://randomuser.me/api/portraits/men/1.jpg',
    },
    {
      name: 'Jane Smith',
      text: 'Absolutely fantastic experience. The team was professional and attentive to our needs.',
      image: 'https://randomuser.me/api/portraits/women/1.jpg',
    },
    {
      name: 'Jane Smith',
      text: 'Absolutely fantastic experience. The team was professional and attentive to our needs.',
      image: 'https://randomuser.me/api/portraits/women/1.jpg',
    },
    // Add more testimonials here
  ];

  return (
    <div id='team' className="mt-6 flex flex-col items-center justify-center p-4">
      <div className="w-full flex justify-center items-center">
        <h1 className="text-[#002e5b] mt-4 font-bold text-xl">Testimonials</h1>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-6">
        <Fade cascade damping={0.3} direction="up">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg max-w-sm mx-auto shadow-xl"
            >
              <div className="flex items-center mb-4">
                <img
                  className="w-16 h-16 rounded-full mr-4"
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <div>
                  <p className="text-lg font-semibold">{testimonial.name}</p>
                  <p className="testimonial-text text-gray-600">
                    “{testimonial.text}”
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </div>
  );
};

