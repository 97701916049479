import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,EffectFade, Pagination, Autoplay } from "swiper";
import Fade from 'react-awesome-reveal';
import * as animationnet from '../animations/Network.json';
import Lottie from 'lottie-react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import hero_2 from "";
// import hero_3 from "../images/hero_3.png";
// import bucket from "../images/hero_3_2.png";
// import buckets from "../images/Bucket.jpeg";
// import basin from "../images/basin.jpg";
import "swiper/css/autoplay";

function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function imgUrl() {
  const id = rand(1, 200);
  return `https://picsum.photos/id/${id}/1920/1080`;
}
export const HomeSlider = (props) => {
  return (
    <div className="!relative h-full  w-full  z-[8]  min-h-[200px] overflow-hidden ">
    <div className="!h-full !flex items-center  justify-center !relative max-w-[100vw] overflow-x-hidden">
      <div className="w-full h-full relative">
        <img
          src="/laptop.jpg"
          className="h-full w-full md:block hidden object-cover"
          alt=""
        />
        <img
          src="/laptop_sm.png"
          className="h-full w-full block md:hidden object-cover"
          alt=""
        />
        <div className="absolute w-full h-full  right-0 left-0 top-0 bottom-0 md:bg-gradient-to-br md:from-[#bee7e7] via-[rgba(255,255,255,0.1)] to-[rgb(0,0,0,0.1)]"></div>
      </div>
      <div className="w-full grid md:grid-cols-2  h-full absolute top-0 bottom-0 right-0 left-0">
        <div className="w-full h-full md:relative absolute  z-10">
          <div className="h-full w-full opacity-5 justify-center flex items-center overflow-x-hidden max-w-[100vw]">
            <div className="  right-0">
              <Lottie
                loop
                animationData={animationnet}
                style={{
                  width: 500,
                }}
              />
            </div>
          </div>
          <div className="w-full h-full  flex items-center justify-center absolute top-0 bottom-0 right-0 left-0">
            <div className="w-full h-full relative">
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                speed={1800}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
                autoplay={{
                  delay: 7000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
              
                className="!absolute !right-0 !top-0 !left-0 !h-full  "
                modules={[Autoplay, EffectFade]}
              >
                <SwiperSlide>
                  <div className="flex w-full h-full justify-center items-center p-3">
                    <div className="md:w-3/4 w-full ">
                      <Fade cascade damping={0.3} direction="up">
                        <h1 className="font-bold md:text-blue-600 text-white text-3xl md:text-5xl md:block hidden ">
                          Mzulia International Limited
                        </h1>
                        <Fade direction="up" cascade damping={0.3}>
                          <h1 className="font-bold md:text-blue-500 text-white text-3xl md:text-5xl md:hidden block mt-3 ">
                            Mzulia International Limited
                          </h1>
                        </Fade>
                        <h1 className="md:mt-8 mt-4 md:text-gray-700 text-sm font-thin text-white text-wrap ">
                          Experts in ICT solutions. We specialize in IT 
                          hardware sales and maintenance and have recently
                          expanded to include Electronic Tax Registers and
                          fiscal solutions. We are dedicated to delivering
                          high-quality technology solutions and support
                          tailored to meet the diverse needs of our clients.
                        </h1>
                      </Fade>{' '}
                    </div>{' '}
                    <div className="rounded-full p-5 animate-bounce absolute top-20 left-10 bg-blue-300"></div>
                    <div className="rounded-full p-5 animate-spin absolute top-10 right-40 bg-black opacity-20"></div>
                  </div>{' '}
                </SwiperSlide>{' '}
                <SwiperSlide>
                  <div className="flex w-full h-full justify-center items-center p-3">
                    <div className="md:w-3/4 w-full">
                      <Fade cascade damping={0.3} direction="up">
                        <h1 className="font-bold md:text-blue-600 text-white  text-3xl md:text-5xl md:block hidden">
                       
                            Mzulia International Limited
                         
                        </h1>
                        <Fade direction="up" cascade damping={0.3}>
                          <h1 className="font-bold md:text-blue-600 text-white text-3xl md:text-5xl md:hidden block mt-3">
                            Mzulia International Limited
                          </h1>
                        </Fade>
                        <h1 className="md:mt-8 mt-4 md:text-gray-700 text-white text-wrap text-sm font-thin">
                          Experts in ICT solutions. We specialize in IT
                          hardware sales and maintenance and have recently
                          expanded to include Electronic Tax Registers and
                          fiscal solutions. We are dedicated to delivering
                          high-quality technology solutions and support
                          tailored to meet the diverse needs of our clients.
                        </h1>
                      </Fade>{' '}
                    </div>{' '}
                    <div className="rounded-full p-5 animate-bounce absolute top-20 left-10 bg-blue-300"></div>
                    <div className="rounded-full p-5 animate-spin absolute top-10 right-40 bg-black opacity-20"></div>
                    <div className="rounded-full p-5 animate-bounce absolute top-20 left-10 bg-blue-300"></div>
                    <div className="rounded-full p-5 animate-spin absolute top-10 right-40 bg-black opacity-20"></div>
                  </div>{' '}
                </SwiperSlide>{' '}
                <SwiperSlide>
                  <div className="flex w-full h-full justify-center items-center p-3">
                    <div className="md:w-3/4 w-full">
                      <Fade cascade damping={0.3} direction="up">
                        <h1 className="font-bold md:text-blue-600 text-white  text-3xl md:text-5xl md:block hidden">
                         
                            Mzulia International Limited
                        
                        </h1>
                        <Fade direction="up" cascade damping={0.3}>
                          <h1 className="font-bold md:text-blue-600 text-white  text-3xl md:text-5xl md:hidden block mt-4">
                            Mzulia International Limited
                          </h1>
                        </Fade>
                        <h1 className="mt-8 md:text-gray-700 text-white text-wrap text-sm font-thin">
                          {' '}
                          Our commitment to excellence and innovation drives
                          us to deliver top-notch solutions and services,
                          meeting the evolving needs of our clients.
                        </h1>
                      </Fade>{' '}
                    </div>{' '}
                    <div className="rounded-full p-5 animate-bounce absolute top-20 left-10 bg-blue-300"></div>
                    <div className="rounded-full p-5 animate-spin absolute top-10 right-40 bg-black opacity-20"></div>
                    <div className="rounded-full p-5 animate-bounce absolute top-20 left-10 bg-blue-300"></div>
                    <div className="rounded-full p-5 animate-spin absolute top-10 right-40 bg-black opacity-20"></div>
                  </div>{' '}
                </SwiperSlide>{' '}
              </Swiper>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>

   
  </div>
  );
};
