import Lottie from 'lottie-react';
import { Bounce, Fade } from 'react-awesome-reveal';

import * as animationworld from '../animations/world spin.json';

// Define the type for props if there are any. Here, we'll assume there are no props.
export const Aboutus = () => {
  return (
    <div id='features' className=" w-full h-full relative ">
      <div>
        <div className="flex flex-col px-[20px] p-3  sm:mt-20 md:mt-0 md:px-[100px] items-center justify-center">
          <div className=" w-full ">
            <h1 className="text-[#002e5b] mt-4 font-bold text-xl">About Us</h1>
          </div>
          <div className="md:grid md:grid-cols-2 items-center gap-5 overflow-hidden">
            <div className="mt-2 ">
              <Fade cascade damping={0.3} direction="up">
                <p className="py-2 md:block hidden text-[15px] text-wrap">
                  Established in October 2020, Mzulia International Ltd
                  commenced operations in March 2021, specializing in high-level
                  ICT solutions and services. Our primary focus includes IT
                  hardware sales and maintenance. In January 2022, we obtained
                  licensing from the Kenya Revenue Authority, expanding our
                  offerings to include Electronic Tax Registers and fiscal
                  solutions tailored to the Kenyan market. Our founding team
                  consists of seasoned information technology professionals with
                  extensive experience in various sectors. This includes
                  expertise in Electronic Tax Registers (KRA Approved since
                  2005), rugged handheld solutions for KPLC meter reading (2007
                  to 2014), and advanced software development.
                </p>
              </Fade>
              <Fade cascade damping={0.3}>
                <p className="py-2 text-[15px] md:hidden block text-wrap">
                  Established in October 2020, Mzulia International Ltd
                  commenced operations in March 2021, specializing in high-level
                  ICT solutions and services. Our primary focus includes IT
                  hardware sales and maintenance. In January 2022, we obtained
                  licensing from the Kenya Revenue Authority, expanding our
                  offerings to include Electronic Tax Registers and fiscal
                  solutions tailored to the Kenyan market. Our founding team
                  consists of seasoned information technology professionals with
                  extensive experience in various sectors. This includes
                  expertise in Electronic Tax Registers (KRA Approved since
                  2005), rugged handheld solutions for KPLC meter reading (2007
                  to 2014), and advanced software development.
                </p>
              </Fade>
              <Fade cascade damping={0.3} direction="up">
                <p className="py-2 text-[15px] text-wrap">
                  At Mzulia International Ltd, we import and supply both new and
                  refurbished ICT hardware, encompassing: Servers, Networking
                  Equipment, Desktop Computers, Laptops, eTims POS Hardware and
                  Software, Our commitment to excellence and innovation drives
                  us to deliver top-notch solutions and services, meeting the
                  evolving needs of our clients.
                </p>
              </Fade>
            </div>

            <div className="relative  pt-4 md:pt-0 ml-auto  md:px-4 justify-center rounded-lg items-center flex flex-col">
              <div className="md:block hidden">
                {/* <div className="xs:h-[50vh] md:h-[100vh] xs:w-[180px] md:w-[200px] bg-[#ffe100]"></div> */}
                <Fade damping={0.3} cascade direction="right">
                  <img src="/Team.png" alt="" />
                </Fade>
              </div>
              <div className="md:hidden block">
                {/* <div className="xs:h-[50vh] md:h-[100vh] xs:w-[180px] md:w-[200px] bg-[#ffe100]"></div> */}
                <Fade damping={0.3} cascade direction="up">
                  <img className='h-80 object-cover rounded-lg' src="/Team.png" alt="" />
                </Fade>
              </div>
              <div className="absolute flex justify-center items-center">
                <div className="h-full w-full opacity-30 justify-center flex items-center overflow-x-hidden max-w-[100vw]">
                  <div className="  mt-32 md:mt-0 overflow-hidden flex justify-center">
                    <Lottie
                      loop
                      animationData={animationworld}
                      style={{
                        width: 500,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3">
          <div className="xs:px-[20px] md:px-[100px] md:pt-0 xs:pt-10  grid md:grid-cols-2 overflow-hidden">
            <div className="hidden md:block ">
              <Fade cascade damping={0.3}>
                <Bounce cascade damping={0.3}>
                  <img className=" " src="/images_1.png" alt="Who We Are Two" />
                </Bounce>
              </Fade>
            </div>
            <div className="flex items-center justify-center">
              <div className="xs:px-[0px] md:px-[45px]">
                <Fade cascade damping={0.3} direction="right">
                  <div>
                    <Fade cascade damping={0.3} direction="up">
                      <h1 className="text-[#002e5b] py-4 text-lg font-semibold">
                        Our Vision
                      </h1>
                      <p className="text-[15px]">
                        Our primary goal is to fully satisfy our customers&apos;
                        desire for quality solutions by delivering innovative
                        and efficient services. We are committed to providing
                        exceptional value through competitive pricing, ensuring
                        that every investment yields the highest return in
                        quality and effectiveness.
                      </p>
                    </Fade>
                  </div>
                  <div>
                    <Fade cascade damping={0.3} direction="up">
                      <h1 className="text-[#002e5b] py-4 text-lg font-semibold">
                        Our Values
                      </h1>
                      <p className="text-[15px]">
                        We prioritize our customers above everything else and
                        are dedicated to providing exceptional value through
                        competitive pricing. Our commitment to quality and
                        excellence is reflected in our teamwork, cooperation,
                        and unwavering integrity. Our people are our greatest
                        asset, and we strive to uphold the highest standards of
                        ethical conduct and professional excellence in all that
                        we do.
                      </p>
                    </Fade>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="pt-2 md:pt-0 block md:hidden  w-full">
              <div className="flex justify-center items-center w-full">
                <Fade cascade damping={0.3} direction="up">
                  <img className='h-80 object-cover rounded-sm' src="/images_1.png" alt="Who We Are Two" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

